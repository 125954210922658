import { Raw } from '@/modules/common/helpers/api';
import { parseISO } from 'date-fns';

export type RawNotificationListResponse = Raw<
  NotificationListResponse,
  {
    // always specify existing raw entry types explititly
    notifications: RawUserNotification[];
  }
>;

export class NotificationListResponse {
  public status: string;
  public notifications: UserNotification[];
  public totalCount: number;
  public unreadCount: number;

  protected constructor(data: RawNotificationListResponse) {
    this.status = data.status;
    this.notifications = data.notifications.map(UserNotification.fromData);
    this.totalCount = data.totalCount;
    this.unreadCount = data.unreadCount;
  }

  public static fromData(data: RawNotificationListResponse): NotificationListResponse {
    return new NotificationListResponse(data);
  }
}

export type RawUserNotification = Raw<
  UserNotification,
  {
    equityID: number | null;
    equityExchangeMarketCode: string | null;
    equityCusip: string | null;
    equityName: string | null;
  },
  'securityID' | 'securityExchangeMarketCode' | 'securityCusip' | 'securityName'
>;

export class UserNotification {
  public id: string;
  public userID: string;
  public subject: string;
  public message: string;
  public category: string;
  public type: string | null;
  public fields: string | null;
  public createdAt: Date;
  public seenAt: Date | null;
  public readAt: Date | null;
  public actionedAt: Date | null;
  public auctionID: string | null;
  public auctionEvent: string | null;
  public auctionEndsAt: Date | null;
  public auctionExecutedAt: Date | null;
  public securityID: number | null;
  public securityExchangeMarketCode: string | null;
  public securityCusip: string | null;
  public securityName: string | null;

  protected constructor(data: RawUserNotification) {
    this.id = data.id;
    this.userID = data.userID;
    this.subject = data.subject;
    this.message = data.message;
    this.category = data.category;
    this.type = data.type;
    this.fields = data.fields;
    this.createdAt = parseISO(data.createdAt);
    this.seenAt = data.seenAt === null ? null : parseISO(data.seenAt);
    this.readAt = data.readAt === null ? null : parseISO(data.readAt);
    this.actionedAt = data.actionedAt === null ? null : parseISO(data.actionedAt);
    this.auctionID = data.auctionID;
    this.auctionEvent = data.auctionEvent;
    this.auctionEndsAt = data.auctionEndsAt === null ? null : parseISO(data.auctionEndsAt);
    this.auctionExecutedAt =
      data.auctionExecutedAt === null ? null : parseISO(data.auctionExecutedAt);
    this.securityID = data.equityID;
    this.securityExchangeMarketCode = data.equityExchangeMarketCode;
    this.securityCusip = data.equityCusip;
    this.securityName = data.equityName;
  }

  public static fromData(data: RawUserNotification): UserNotification {
    return new UserNotification(data);
  }
}
